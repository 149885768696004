/* Funktsioon, mille abil asustatakse "Küsi pakkumist" ja "Tule proovisõidule" vormides mudelite valikud */

function populateModels(testdrive) {
    if (testdrive === true) {
        // Kui päring tehakse proovisõiduvormis
        var modelsSelect = jQuery('#testdrivemodels');
    }
    else {
        // Kui päring hinnapakkumise vormis
        var modelsSelect = jQuery('#models');
    }
    var post_id = jQuery("#offerTab").data("postid");
    var nonce = jQuery("#offerTab").data("nonce");

    jQuery.ajax({
        type : "post",
        dataType : "json",
        url : populateModelsAjax.ajaxurl,
        data : {action: "populate_models", post_id : post_id, nonce: nonce},
        success: function(response) {
            if(response.type == "success") {
                modelsSelect.find('option').remove(); // Varasemad rippmenüü elemendid tühjendatakse

                jQuery.each(response.models, function(i, value) { // Iga vastuseks saadud JSON array element lisatakse varustustasemete rippmenüüsse

                    modelsSelect.append(jQuery('<option>').text(value.post_title).attr('value', value.ID));

                    if (value.ID===post_id) {
                        modelsSelect.val(value.ID);
                    }
                });

                if (testdrive === true) {
                    populateModelPacks(true);
                }
                else {
                    populateModelPacks();
                }
            }
            else {
               console.log("Error in populateModels");
            }
        }
    });
}